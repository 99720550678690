import { AxiosInstance } from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import {
  Box,
  Spacer,
  Spinner,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react"
import { EquityPartnerNavigationBar, EquityPartnerDashboardSearchBar, Navigation} from "./ui"
import { DealsComponent } from "../Deals"
import { getIsLoggedIn, getUser } from "../../hooks"
import { NavOption, Deal, UserType, } from "../../types"
import { DealManagerDealComponent } from "../Deal"

const LeftPanelContainer = styled(Box)``
LeftPanelContainer.defaultProps = {
  width: 'calc(100% / 5)',
  height: 'calc(100%)',
  position: 'fixed',
  left: 0,
  top: "60px",
  // background: "#5856e7",
  background: '#fafbfb',
  overflowY: 'scroll',
  overflowX: 'hidden',
  borderRight: "1px solid #edebeb"
}

const LargeContainer = styled(Box)``
LargeContainer.defaultProps = {
  width: '80%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '20%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

const MobileContainer = styled(Box)``
MobileContainer.defaultProps = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '0',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

interface Props {
  currentNavOption: string
  axios: AxiosInstance
}

export const EquityPartnerDashboardPage = ({ currentNavOption, axios }: Props) => {
  const navigate = useNavigate()
  const params = useParams()
  
  const [deal, setDeal] = useState<Deal | null>(null)
  const [deals, setDeals] = useState<Deal[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    const isLoggedIn = getIsLoggedIn()
    const { id } = params

    if (isLoggedIn) {
      if(NavOption.CLIENT === currentNavOption && id) {
        fetchDealbyId(id)
      }
    }
  }, [currentNavOption, params])

  
  //fetch deal for offer page
  const fetchDealbyId = async (id: string) => {
    try {
      const user = await getUser()
      if (user && user.type === UserType.DEAL_MANAGER) {
        const { didToken } = user
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/deal-manager/deal/${id}`, {
          headers: { Authorization: `Bearer ${didToken}` }
        })
        const { data } = response
        if (response.status === 200 && data?.success) {
          setDeal(data.deal)
        }
      } else {
        navigate(`/login`)
      }
    } catch(e) {
      console.log(e)
      navigate(`/login`)
    }
  }
  
  const searchDealsBySearchText = async (searchText: string) => {
    setIsLoading(true)
    try {
      const user = await getUser()
      if (user && user.type === UserType.DEAL_MANAGER) {
        const { didToken } = user
        const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/deal-manager/deals/search/${searchText}`, {
          headers: { Authorization: `Bearer ${didToken}` }
        })
        setIsLoading(false)
        const { data } = response
        if (response.status === 200 && data?.success) {
          setDeals(data.deals)
        }
      } else {
        navigate(`/login`)
      }
    } catch(e) {
      console.log(e)
      setIsLoading(false)
      navigate(`/login`)
    }
  }

  
  const getComponent = () => {
    if (currentNavOption === NavOption.CLIENT) {
      if (deal) {
        return (
          <DealManagerDealComponent deal={deal} />
        )
      }
    } else if (currentNavOption === NavOption.DASHBOARD) {
      return (
        <Stack h="100%" pb={10}>
          <EquityPartnerDashboardSearchBar searchDeals={searchDealsBySearchText} />
          {
            isLoading ? (
              <VStack h="100%">
                <Spacer />
                <Spinner
                  thickness='4px'
                  speed='0.65s'
                  emptyColor='gray.200'
                  color='blue.500'
                  size='xl'
                />
                <Spacer />
              </VStack>
            ) : (
              <DealsComponent deals={deals} />
            )
          }
        </Stack>
      )
    }
  }

  return (
    <>
      <EquityPartnerNavigationBar />
      <LeftPanelContainer>
        <Text fontSize={"lg"} color="brand.300" fontWeight="bold" p={5} onClick={() => navigate(`/dashboard`)}>
          Deal Portal
        </Text>
      <Navigation />
      </LeftPanelContainer>
      <Stack h='100vh'>
        <LargeContainer>
          { getComponent() }
        </LargeContainer>
      </Stack>
    </> 
  )
}