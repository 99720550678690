import axios from "axios"
import { useEffect, useState } from "react"
import { FiChevronDown } from "react-icons/fi"
import { useNavigate } from "react-router-dom"
import { Box, Button, Flex, HStack, IconButton, Image, Menu, MenuButton, MenuItem, MenuList, Slide, Spacer, Stack, Text } from "@chakra-ui/react"
import { getUser, removeSessionCookie, useDevice, useUserContext } from "../../../hooks"
import { openInNewTab } from "../../../utils"
import { useDisclosure } from "@chakra-ui/hooks"
import { HiMenu } from "react-icons/hi"
import { Navigation } from "./Navigation"
import { DealManager } from "../../../types"
import { DealManagerPanel } from "./DealManagerPanel"

interface Props{
  menu?: boolean
  dealManager?:DealManager | null
}

export const NavigationBar = ( {menu, dealManager}:Props ) => {
  const [name, setName] = useState("Account")
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const user = useUserContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {isMobile} = useDevice()
  const {isOpen:isNavOpen, onToggle} = useDisclosure()

  useEffect(() => {
    const fetchMe = async () => {
      const _user = await getUser()
      if (_user) {
        setName(`${_user.firstName} ${_user.lastName}`)
      } else {
        if (isLoading) {
          const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/me`, {}, { withCredentials: true })
          const { data } = res
          if (data.success) {
            setName(`${data.user.firstName} ${data.user.lastName}`)
          }
          setIsLoading(false)
        }
      }
    }

    if (!user) {
      fetchMe()
      setIsLoading(true)
    } else {
      setName(`${user.firstName} ${user.lastName}`)
    }
  }, [user, isLoading, setIsLoading])

  const handleSignOut = async () => {
    try {
      removeSessionCookie()
      navigate(`/login`)
    } catch(e) {
      navigate(`/login`)
    }
  }

  return (
    isMobile ? (
      <Stack>
        <Flex w="100%" bg="brand.300" p={2} h="60px" position={'absolute'} style={{ zIndex: 1000 }}>
          {
          menu !== false && (
            <>
              <IconButton aria-label="Menu" size={'lg'} onClick={onToggle} icon={<HiMenu />} colorScheme=""/>
            </>
          )}
          <Spacer />
          <Image src="/images/eso_logo_white.png" h={50} cursor="pointer" onClick={() => navigate(`/portfolio`)} position={"absolute"} left={"50%"} marginLeft={"-79"}/>
          <Box pr={0} pt={'4px'}>
            <Menu>
              <MenuButton pr={0} as={Button} rightIcon={<FiChevronDown />} bg="transparent" color="white" >
                { name }
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => navigate("/account")}>Account</MenuItem>
                <MenuItem onClick={() => navigate("/resources")}>Resources</MenuItem>
                <MenuItem onClick={() => navigate("/terms")}>Terms of Service</MenuItem>
                <MenuItem onClick={() => navigate("/privacy-policy")}>Privacy Policy</MenuItem>
                <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
        <Slide direction='left' in={isNavOpen} style={{ zIndex: 100 }}>
          <HStack p={0} pt={"50px"} spacing={0}>
            <Box bg={"#fafbfb"} borderRight={"1px solid #edebeb"} m={0} p={0} w={"70vw"} h={'100vh'} zIndex={"1000"}>
              <Navigation />  
              <Spacer />
              {
              dealManager && (
                <DealManagerPanel dealManager={dealManager} />
                )
              }
            </Box> 
            <Box m={0} p={0} top={'52px'} bg={''} mt={0} w={"30vw"} h={'100vh'} zIndex={"1000"} onClick={onToggle} />
          </HStack>
          
        </Slide >
      </Stack>
    ) : (
    <HStack w="100%" bg="brand.300" pt={2} pb={2} h="60px">
      <Image src="/images/eso_logo_white.png" pl={5} h={50} cursor="pointer" onClick={() => navigate(`/portfolio`)}/>
      <Spacer />
      <Text color="white" bg="transparent" as={Button} fontWeight="bold" pr={5} onClick={() => openInNewTab(`https://esofund.beehiiv.com/`)}>
        Newsletter
      </Text>
      <Box pr={5}>
        <Menu isOpen={isOpen}>
          <MenuButton as={Button} rightIcon={<FiChevronDown />} bg="transparent" color="white" onMouseEnter = {onOpen} onMouseLeave = {onClose}>
            { name }
          </MenuButton>
          <MenuList onMouseEnter = {onOpen} onMouseLeave = {onClose}>
            <MenuItem onClick={() => navigate("/account")}>Account</MenuItem>
            <MenuItem onClick={() => navigate("/resources")}>Resources</MenuItem>
            <MenuItem onClick={() => navigate("/terms")}>Terms of Service</MenuItem>
            <MenuItem onClick={() => navigate("/privacy-policy")}>Privacy Policy</MenuItem>
            <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </HStack>
    )
  )
}