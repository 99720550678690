import { Avatar, Button, HStack, Spacer, Stack, Text, VStack } from "@chakra-ui/react"
import { DealManager } from "../../../types"
import { openInNewTab } from "../../../utils"

interface Props {
  dealManager: DealManager
}

export const DealManagerPanel = ({ dealManager }: Props) => {
  const { firstName, lastName, email, phoneNumber, profilePicture } = dealManager
  
  return (
    <VStack pt={10} pb={20}>
      <HStack w="100%">
        <Text
          fontSize="lg"
          color="brand.300"
          p={2}
          pl={10}
        >
          Equity Partner:
        </Text>
        <Spacer />
      </HStack>
      <HStack spacing={5} pb={10}>
        <Avatar name={`${firstName} ${lastName}`} w={50} h={50} src={profilePicture} />
        <Stack spacing={0}>
          <Text fontSize="sm">
            { `${firstName} ${lastName}` }
          </Text>
          <Text fontSize="sm">{ email }</Text>
          <Text fontSize="sm">{ phoneNumber }</Text>
        </Stack>
      </HStack>
      <Button
        bg="brand.100"
        color="white"
        variant="solid"
        mb={10}
        onClick={() => openInNewTab(dealManager.calendly)}
      >
        Schedule a call
      </Button>
    </VStack>
  )
}
