import { Button, Text, VStack } from "@chakra-ui/react"
import { openInNewTab } from "../../../utils"

export const ReviewComponent = () => {
  return (
    <VStack pl={10} pr={10}>
      <Text fontSize="xl" pb={10}>
        Happy with your ESO experience?
      </Text>
      <Button bg="brand.300" color="white" onClick={() => openInNewTab("https://g.page/r/CYis6AvKG1gnEBM/review")}>
        Write a Review
      </Button>
    </VStack>
  )
}