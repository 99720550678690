import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react"
import { NegativeButton, UploadComponent } from "../../ui"
import { Deal } from "../../../types"

interface Props {
  deal: Deal
  isOpen: boolean
  id: string
  addDocument: (key: string) => void
  onClose: () => void
}

export const UploadFilesModal = ({ deal, isOpen, id, addDocument, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="white">
        <ModalHeader>Upload Files</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="100%" p={5}>
            <UploadComponent deal={deal} documentKey={id} addDocument={addDocument} onClose={onClose} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <NegativeButton mr={3} onClick={onClose}>
            Close
          </NegativeButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
