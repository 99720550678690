import {
  Button,
  chakra,
} from "@chakra-ui/react"

export const PositiveButton = chakra((props) => {
  return (
    <Button
      {...props}
      bg="#3F5EA1"
      color="white"
    />
  )
})