import axios from "axios"
import { useEffect, useState } from "react"
import { Deal, DealDocument, DealDocumentType } from "../../types"
import { DocumentsComponent } from "./ui"
import { getUser } from "../../hooks"

interface Props {
  deal: Deal
}

export const DealComponent = ({ deal }: Props) => {
  const [documents, setDocuments] = useState<DealDocument[]>([])

  useEffect(() => {
    const fetchDocumentsByDealId = async () => {
      const me = await getUser()
      const { didToken } = me
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/documents/${deal.id}`, {
        headers: { Authorization: `Bearer ${didToken}` }
      })

      const { documents } = data
      setDocuments(documents)
    }
    fetchDocumentsByDealId()
  }, [deal])

  const addDocument = async (key: string) => {
    const _documents = [...documents]
    _documents.push({ id: 0, type: key as DealDocumentType })
    setDocuments(_documents)
  }

  return (
    <DocumentsComponent
      deal={deal}
      documents={documents}
      addDocument={addDocument}
    />
  )
}