import { useEffect, useState } from "react"
import { BsBook } from "react-icons/bs"
import { FaRegHandshake } from "react-icons/fa"
import { IoMdDocument } from "react-icons/io"
import { MdAttachMoney, MdDashboard } from "react-icons/md"
import { VscAccount } from "react-icons/vsc"
import { useLocation, useNavigate } from "react-router-dom"
import { Box, HStack, Link, Text, Stack } from "@chakra-ui/react"
import { ALL_NAV_OPTIONS, DEAL_MANAGER_NAV_OPTIONS, NavOptionDict } from "../../../constants"
import { getUser } from "../../../hooks"
import { NavOption, OfferStatus, UserType } from "../../../types"

interface NavigationIconProps {
  navOption: string
  isCurrentNavOption: boolean
}

interface NavigationItemProps {
  navOption: NavOption
  isCurrentNavOption: boolean
  currentNavOption: string
  navPortal: "client" | "dealManager"
}

const NavigationIcon = ({ navOption, isCurrentNavOption }: NavigationIconProps) => {
  const color = isCurrentNavOption ? "white" : "#989eb2"
  const getIconByNavOption = () => {
    if (navOption === NavOption.CURRENT_OFFER) {
      return (
        <FaRegHandshake color={color} />
      )
    } else if (navOption === NavOption.DOCUMENTS) {
      return (
        <IoMdDocument color={color} />
      )
    } else if (navOption === NavOption.TAX_CALCULATION) {
      return (
        <MdAttachMoney color={color} />
      )
    } else if (navOption === NavOption.RESOURCES) {
      return (
        <BsBook color={color} />
      )
    } else if (navOption === NavOption.ACCOUNT) {
      return (
        <VscAccount color={color} />
      )
    } else if (navOption === NavOption.DASHBOARD) {
      return (
        <MdDashboard color={color} />
      )
    }
    // else if(navOption === NavOption.BACKGROUND_CHECK) {
    //   return (
    //     <MdSecurity color={color} />
    //   )
    // }
  }
  return (
    <Box pl={5}>
      { getIconByNavOption() }
    </Box>
  )
}

const NavigationItem = ({ navOption, isCurrentNavOption, currentNavOption, navPortal }: NavigationItemProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  
  const handleNavChange = () => {
    if (navPortal === "dealManager") {
      navigate(`/${NavOptionDict[navOption]}`)
    } else if (navPortal === "client") {
      const urlArr = location.pathname.split("/")
      const newUrl = urlArr.slice(0, -1).join("/")
      navigate(`${newUrl}/${NavOptionDict[navOption]}`)
    } else {
      navigate('/login')
    }
  }

  return (
    <HStack p={4} borderRadius={10} bg={ isCurrentNavOption ? "brand.300" : "transparent" }>
      <NavigationIcon navOption={navOption} isCurrentNavOption={isCurrentNavOption} />
      <Text
        as={Link}
        pl={5}
        fontSize={15}
        color={ isCurrentNavOption ? "white" : "#989eb2" }
        fontWeight={ isCurrentNavOption ? "bold" : "medium" }
        onClick={handleNavChange}
      >
        { currentNavOption.replaceAll("_", " ").replace(/(^\w|\s\w)/g, m => m.toUpperCase()) }
      </Text>
    </HStack>
  )
}

enum MenuType {
  NA = "n/a",
  CLIENT = "client",
  DEAL_MANAGER = "deal_manager"
}

interface Props {
  stage?: OfferStatus
}

export const Navigation = ({stage}:Props) => {
  const location = useLocation()
  const [menuType, setMenuType] = useState<MenuType>(MenuType.NA)

  useEffect(() => {
    const getMenuType = async () => {
      const user = await getUser()
      if (user) {
        const { type } = user
        if (type === UserType.CLIENT) {
          setMenuType(MenuType.CLIENT)
        } else if (type === UserType.DEAL_MANAGER) {
          setMenuType(MenuType.DEAL_MANAGER)
        }
      }
    }
    
    getMenuType()
  }, [])

  const getView = () => {
    switch(menuType) {
      case MenuType.CLIENT:
        return (
          <>
            {
              ALL_NAV_OPTIONS.map(navOption => {
                const isCurrentNavOption = location.pathname.includes(NavOptionDict[navOption])
                return (
                  <Box key={navOption} pl={5} pr={5}>
                    <NavigationItem isCurrentNavOption={isCurrentNavOption} navOption={navOption} currentNavOption={navOption} navPortal={"client"}/>
                  </Box>
                )
              })
            }
            {/* {
              stage === OfferStatus.PRE_CLOSING &&(
                <Box pl={5} pr={5}>
                    <NavigationItem isCurrentNavOption={location.pathname.includes(NavOptionDict[NavOption.BACKGROUND_CHECK])} navOption={NavOption.BACKGROUND_CHECK} currentNavOption={NavOption.BACKGROUND_CHECK} navPortal={"client"}/>
                </Box>
              )
            } */}
          </>
        )
      case MenuType.DEAL_MANAGER:
        return (
          <>
            {
              DEAL_MANAGER_NAV_OPTIONS.map(navOption => {
                const isCurrentNavOption = location.pathname.includes(NavOptionDict[navOption]) 
                const _navOption = navOption
                return (
                  <Box key={navOption} pl={5} pr={5}>
                    <NavigationItem isCurrentNavOption={isCurrentNavOption} navOption={navOption} currentNavOption={_navOption}  navPortal={"dealManager"}/>
                  </Box>
                )
              })
            }
          </>
        )
    }
  }

  return (
    <Stack pt={10}>
      {
        getView()
      }
    </Stack>
  )
}
