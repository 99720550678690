import { Box, HStack, Link, Spacer, Stack, Text } from "@chakra-ui/react"
import {
  get409aProofInstructions,
  ID_MUTUAL_NDA,
  ID_409A_PROOF,
  ID_OPTIONS_VESTING_PROOF,
  ID_BC,
  ID_OPTION_PLAN,
  ID_OPTION_GRANT,
  ID_DRIVERS_LICENSE,
  INSTRUCTIONS_NDA,
  INSTRUCTIONS_BC,
  INSTRUCTIONS_OPTIONS_AND_SHARES,
  INSTRUCTIONS_STOCK_OPTION_PLAN,
  INSTRUCTIONS_OPTION_GRANT_DOCUMENTS,
} from "../constants"
import { DocumentTypeDict } from "../../../constants"
import { Deal, OfferStatus } from "../../../types"
import { openInNewTab } from "../../../utils"
import { DocumentLabel } from "./DocumentLabel"
import { EsoDocumentIcon } from "./EsoDocumentIcon"

interface Props {
  deal: Deal
}

const IntroInstructions = ({ deal }: Props) => {
  const INSTRUCTIONS_409A_PROOF = get409aProofInstructions(deal.company.name)
  const REQUIRED_DOCS = 
  [
    {
      id: ID_409A_PROOF,
      type: DocumentTypeDict.fmvProofDocument,
      label: "409A Proof",
      description: "Equity portal screenshot or company email trail confirming the current fair market value. **IMPORTANT**: Please make sure the **company name** and **current date** appear in any screenshot.",
      instructions: INSTRUCTIONS_409A_PROOF,
      icon: "409A",
    },
    {
      id: ID_OPTIONS_VESTING_PROOF,
      type: DocumentTypeDict.optionsVestingProofDocument,
      label: "Stock Options (or Shares) Vesting Proof",
      description: "Equity portal screenshot or grant documents confirming number of options, grant date, and strike price.",
      instructions: INSTRUCTIONS_OPTIONS_AND_SHARES,
      icon: "#",
    },
  ]

  return (
    <Stack>
      <Text
        as={Link}
        pb={5}
        fontSize="xl"
        fontWeight="bold"
        color="brand.100"
        onClick={() => openInNewTab(deal.dealManager.calendly)}
      >
        { `Click here to schedule your intro call with ${deal.dealManager.firstName}` }
      </Text>
      <EsoDocumentIcon
        id={ID_MUTUAL_NDA}
        name="Sign Our Mutual NDA"
        instructions={INSTRUCTIONS_NDA}
      />
      {
        REQUIRED_DOCS.map((doc, index) => (
          <DocumentLabel
            id={doc.id}
            key={index}
            type={doc.type}
            label={doc.label}
            instructions={doc.instructions}
            description=""
            icon={doc.icon}
          />
        ))
      }
    </Stack>
  )
}

const OfferInstructions = () => {
  const REQUIRED_DOCS = 
  [
    {
      id: ID_OPTION_PLAN,
      type: DocumentTypeDict.optionPlanDocument,
      label: "Stock Option Plan",
      instructions: INSTRUCTIONS_STOCK_OPTION_PLAN,
      icon: "SOP",
    },
    {
      id: ID_OPTION_GRANT,
      type: DocumentTypeDict.optionGrantDocument,
      label: "Stock Option Grant Document (for each grant)",
      instructions: INSTRUCTIONS_OPTION_GRANT_DOCUMENTS,
      icon: "SOG",
    },
  ]

  return (
    <Stack>
      <EsoDocumentIcon
        id={ID_BC}
        name="Sign our Background Check Permission Form"
        instructions={INSTRUCTIONS_BC}
      />
      {
        REQUIRED_DOCS.map((doc, index) => (
          <DocumentLabel
            id={doc.id}
            key={index}
            type={doc.type}
            label={doc.label}
            instructions={doc.instructions}
            description=""
            icon={doc.icon}
          />
        ))
      }
    </Stack>
  )
}

const ContractInstructions = () => {
  return (
    <Stack>
      <EsoDocumentIcon
        id="sign-contract"
        name="Sign Contract on DocuSign"
        instructions=""
      />
      <EsoDocumentIcon
        id="sign-lpoa"
        name="Sign and Notarize LPOA in your email"
        instructions=""
      />
      <DocumentLabel
        id={ID_DRIVERS_LICENSE}
        type={DocumentTypeDict.driversLicenseDocument}
        label="Upload 2 Forms of ID (Driver's License & Passport)"
        instructions=""
        description=""
        icon="ID"
      />
      <EsoDocumentIcon
        id="closing"
        name="Schedule your Closing Meeting"
        instructions=""
      />
    </Stack>
  )
}

export const ToDoBox = ({ deal }: Props) => {
  const getWhatsNextInstructions = () => {
    const { stage } = deal
    switch(stage) {
      case OfferStatus.INTRO:
        return (
          <IntroInstructions deal={deal} />
        )
      case OfferStatus.ESO_REVIEW:
        return (
          <OfferInstructions />
        )
      case OfferStatus.OFFER_READY:
        return (
          <OfferInstructions />
        )
      case OfferStatus.PRE_CLOSING:
        return (
          <ContractInstructions />
        )
      case OfferStatus.CONTRACT_REVIEW:
        return (
          <ContractInstructions />
        )
      default:
        return (
          <IntroInstructions deal={deal} />
        )
    }
  }

  return (
    <Box pl={10} pr={10}>
      <HStack bg="#9EAED0" border="3px solid" m={10} borderRadius={5}>
        <Stack h="100%" w="20%">
          <Text fontWeight="bold" pl={10} fontSize="2xl" color="white">
            To-Do
          </Text>
        </Stack>
        <Box h="100%" w={2} color="#FEFFFE" />
        <Stack w="80%" bg="white" pt={10} pl={10} pr={10} h="100%">
          {
            getWhatsNextInstructions()
          }
          <Spacer />
          <HStack w="100%" pb={5}>
            <Spacer />
            <Text fontSize="sm" color="brand.300">
              Scroll down for more information on the above
            </Text>
          </HStack>
        </Stack>
      </HStack>
    </Box>
  )
}