import React, { useState } from "react";
import { BiInfoCircle } from "react-icons/bi"
import { IoIosCheckmarkCircle } from "react-icons/io";
import { Box, Button, Center, HStack, Icon, List, ListItem, Spacer, Stack, Text, Tooltip } from "@chakra-ui/react"
import { INSTRUCTIONS_ID_START, INSTRUCTIONS_ID_MID, INSTRUCTIONS_ID_END } from "../constants"
import { UploadFilesModal } from "../modals"
import { DocumentTypeDict } from "../../../constants"
import { useDevice } from "../../../hooks"
import { Deal, DocumentRow } from "../../../types"

interface Props {
  deal: Deal
  documentRow: DocumentRow
  isUploaded: boolean
  addDocument: (key: string) => void
}

export const UploadDocumentRow = ({
  deal,
  documentRow,
  isUploaded,
  addDocument,
}: Props) => {
  const [isUploadFilesModalOpen, setIsUploadFilesModalOpen] = useState(false)

  const idTooltipLabel = () => {
    return (
      <Stack>
        <Text>
          { INSTRUCTIONS_ID_START }
        </Text>
        <List>
          <ListItem>
            { INSTRUCTIONS_ID_MID }
          </ListItem>
          <ListItem>
            { INSTRUCTIONS_ID_END }
          </ListItem>
        </List>
      </Stack>
    )
  }
  const {isMobile} = useDevice()

  const renderTextWithBold = (description: string) => {
    const words = description.split(" ");
    return words.map((word: string, index: number) => {
      if (word.startsWith("**") || word.endsWith("**")) {
        return (
          <strong key={index}>{word.replaceAll("**", "")} </strong>
        )
      } else {
        return <React.Fragment key={index}>{word} </React.Fragment>;
      }
    })
  }

  return (
    <Box pt={5} id={documentRow.id}>
      <HStack pb={5}>
        {
          !isMobile && (
          <Center w={55} h={55} bg="brand.300" borderRadius={50}>
            <Text color="white" fontSize="lg">
              { documentRow.icon }
            </Text>
          </Center>
          )
        }
        
        <Stack>
          <Tooltip label={documentRow.type === DocumentTypeDict.driversLicenseDocument || documentRow.type === DocumentTypeDict.passportDocument ? idTooltipLabel() : documentRow.instructions} placement="bottom">
            <HStack>
              <Text textAlign="left" fontWeight="bold">
                { documentRow.label }
              </Text>
              {
                !isMobile && (
                  <Icon as={BiInfoCircle} h={4} w={4} color="brand.300" />
                )
              }
              {
                isUploaded && (
                  <Icon as={IoIosCheckmarkCircle} h={4} w={4} color="green" />
                )
              }
            </HStack>
          </Tooltip>
          <Text textAlign="left" fontSize="sm" color="gray.500">
            { renderTextWithBold(documentRow.description) }
          </Text>
        </Stack>
      </HStack>
      <Box className="file-upload-container">
        <Button bg="#3F5EA1" color="white" onClick={() => setIsUploadFilesModalOpen(true)}>
          Select Files
        </Button>
      </Box>
      <UploadFilesModal
        deal={deal}
        id={documentRow.type}
        isOpen={isUploadFilesModalOpen}
        addDocument={addDocument}
        onClose={() => setIsUploadFilesModalOpen(false)}
      />
    </Box>
  )
}
