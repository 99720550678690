import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import {
  Client,
  Company,
  Deal,
  DealManager,
  NavOption,
  Resource,
} from "../../types"
import { Box, HStack, Spacer, Stack, Text } from "@chakra-ui/react"
import { CopyrightFooter, DealManagerPanel, Navigation, NavigationBar, ReturnToPortfolioLink } from "./ui"
import { NoDealComponent } from "../Offer"
import { TaxCalculationComponent } from "../Tax"
import { ResourcesComponent } from "../Resources"
import { getIsLoggedIn, useDevice, getUser } from "../../hooks"
import { DealComponent } from "../Deal"

const LeftPanelContainer = styled(Stack)``
LeftPanelContainer.defaultProps = {
  width: 'calc(100% / 5)',
  height: 'calc(100%)',
  position: 'fixed',
  left: 0,
  top: "60px",
  paddingTop: "20px",
  paddingBottom: "20px",
  // background: "#5856e7",
  background: '#fafbfb',
  overflowY: 'scroll',
  overflowX: 'hidden',
  borderRight: "1px solid #edebeb"
}

const LargeContainer = styled(Box)``
LargeContainer.defaultProps = {
  width: '80%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '20%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

const MobileContainer = styled(Box)``
MobileContainer.defaultProps = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '0',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

interface Props {
  currentNavOption: string
}

export const DashboardPage = ({ currentNavOption }: Props) => {
  const params = useParams()
  const { id } = params
  const {isMobile} = useDevice()
  const [deal, setDeal] = useState<Deal | null>(null)
  const [company, setCompany] = useState<Company | null>(null)
  const [dealManager, setDealManager] = useState<DealManager | null>(null)
  const [resources, setResources] = useState<Resource[]>([])
  
  useEffect(() => {
    const parseDeals = (client: Client) => {
      const { deals } = client
      if (deals.length) {
        if (id) {
          const _deal = deals.find(d => d.id === parseInt(id))
          if (_deal) {
            const { company, dealManager } = _deal
            setCompany(company)
            setDeal(_deal)
            setDealManager(dealManager)
          }
        }
      } else {
        const { dealManager } = client
        if (dealManager) {
          setDealManager(dealManager)
        }
      }
    }

    const fetchClient = async () => {
      try {
        const me = await getUser()
        const { didToken } = me
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/client/`, {}, {
          headers: { Authorization: `Bearer ${didToken}` }
        })
        const { data } = response
        if (response.status === 200 && data?.success) {
          const _client: Client = data.client
          parseDeals(_client)
        } else {
          // navigate(`/login`)
        }
      } catch(e) {
        // navigate(`/login`)
      }
    }

    const fetchDocuments = async () => {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/resources/all`)
      const { data } = response
      if (data && data.success) {
        const _resources: Resource[] = data.resources
        setResources(_resources)
      }
    }
    
    const isLoggedIn = getIsLoggedIn()

    if (isLoggedIn) {
      fetchClient()
      fetchDocuments()
    }
  }, [])

  const getMainContainerComponent = () => {
    if (currentNavOption === NavOption.CURRENT_OFFER) {
      if (deal) {
        return (
          <DealComponent deal={deal} />
        )
      } else {
        if (dealManager) {
          return (
            <NoDealComponent
              dealManager={dealManager}
            />
          )
        }
      }
    } else if (currentNavOption === NavOption.TAX_CALCULATION) {
      return (
        <TaxCalculationComponent />
      )
    } else if (currentNavOption === NavOption.RESOURCES) {
      return (
        <ResourcesComponent resources={resources} />
      )
    }
  }

  
  return (
    <>
      {
        isMobile ? (
          <>
            <NavigationBar dealManager={dealManager} />
            <Stack h='100vh'>
              <MobileContainer>
                { getMainContainerComponent() }
                <Box p={20}>
                  <CopyrightFooter />
                </Box>
              </MobileContainer>
            </Stack>
          </>
        ) : (
          <>
            <NavigationBar />
            <LeftPanelContainer>
              <HStack pl={5} pr={5}>
                <Text fontSize="lg" fontWeight="bold" color="brand.300">
                  { company ? company.name : "" }
                </Text>
                <Spacer />
              </HStack>
                <Navigation stage={deal?.stage} />
              <Spacer />
              {
                dealManager && (
                  <DealManagerPanel dealManager={dealManager} />
                )
              }
            </LeftPanelContainer>
            <Stack h='100vh'>
              <LargeContainer>
                <ReturnToPortfolioLink />
                { getMainContainerComponent() }
                <Box p={20}>
                  <CopyrightFooter />
                </Box>
              </LargeContainer>
            </Stack>
          </>
        )
      }
    </>
  )
}
