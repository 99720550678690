import { Stack } from "@chakra-ui/react"
import { DealItem } from "./ui"
import { Deal } from "../../types"

interface Props {
  deals: Deal[]
}

export const DealsComponent = ({ deals }: Props) => {
  return (
    <Stack pl={10} pr={10} pt={10} pb={20}>
      {
        deals.map(deal => {
          return (
            <DealItem key={deal.id} deal={deal} />
          )
        })
      }
    </Stack>
  )
}
