import { Stack, Text } from "@chakra-ui/react"
import { ReviewComponent } from "./ReviewComponent"

export const LiquidityComponent = () => {
  return (
    <Stack pl={10} pr={10}>
      <Text fontSize="md" pb={10}>
        You have completed your ESO transaction process, and now we wait for Liquidity!
      </Text>
      <Text fontSize="md" pb={10}>
        If you have any questions or have any information relevant to the company, please don’t hesitate to reach out to your Equity Partner.
      </Text>
      <Text fontSize="md" pb={10}>
        Anything from things you hear from ex-coworkers to opportunities to sell on the secondary market, we are happy to chat. You can even just check in and get ESO’s take on the current state of the company!
      </Text>
      <Text fontSize="md" pb={10}>
        Thank you for being a valued client of the ESO Fund!
      </Text>

      <ReviewComponent />
    </Stack>
  )
}