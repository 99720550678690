import { Divider, Text } from "@chakra-ui/react"
import { Deal, DealDocument, OfferStatus } from "../../../types"
import {
  INSTRUCTIONS_NDA_UNSIGNED,
  INSTRUCTIONS_NDA_SIGNED,
  INSTRUCTIONS_CONTRACT_REVIEW
} from "../constants"
import { DealDocuments, ToDoBox } from "./"

interface Props {
  deal: Deal
  documents: DealDocument[]
  hideClosingDocs: boolean
  hideContractDocs: boolean
  addDocument: (key: string) => void
  requestForDocument?: (documentType: string) => Promise<void>
}

export const PreClosingComponent = ({
  deal,
  documents,
  hideClosingDocs,
  hideContractDocs,
  addDocument,
  requestForDocument
}: Props) => {

  const getInstructionText = (deal: Deal) => {
    const { stage } = deal

    switch(stage) {
      case OfferStatus.INTRO:
        return INSTRUCTIONS_NDA_UNSIGNED
      case OfferStatus.ESO_REVIEW:
        return INSTRUCTIONS_NDA_SIGNED
      case OfferStatus.OFFER_READY:
        return INSTRUCTIONS_NDA_SIGNED
      case OfferStatus.PRE_CLOSING:
        return INSTRUCTIONS_CONTRACT_REVIEW
      case OfferStatus.CONTRACT_REVIEW:
        return INSTRUCTIONS_CONTRACT_REVIEW
      default:
        return ""
    }
  }

  return (
    <>
      <ToDoBox deal={deal} />
      <Text fontSize="md" p={10}>
        { getInstructionText(deal) }
      </Text>
      <Divider />
      <DealDocuments
        deal={deal}
        documents={documents}
        hideClosingDocs={hideClosingDocs}
        hideContractDocs={hideContractDocs}
        addDocument={addDocument}
        requestForDocument={requestForDocument}
      />
    </>
  )
}