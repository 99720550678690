import axios from "axios"
import { useEffect } from "react"
import { HStack, Stack, Text, useToast } from "@chakra-ui/react"
import { getUser } from "../../../hooks"
import { Deal, DealDocument, OfferStatus } from "../../../types"
import {
  DOCUMENT_TYPE_NDA,
  DOCUMENT_TYPE_BACKGROUND_CHECK
} from "../constants"
import { LiquidityComponent, PreClosingComponent } from "./"

interface Props {
  deal: Deal
  documents: DealDocument[]
  addDocument: (key: string) => void
}

export const DocumentsComponent = ({ deal, documents, addDocument }: Props) => {
  const toast = useToast()
  
  useEffect(() => {
    const element = document.getElementById("top")
    if (element) {
      element.scrollIntoView({ behavior: "auto" })
    }
  }, [])

  const getWhatsNextText = (deal: Deal) => {
    const { stage } = deal

    switch(stage) {
      case OfferStatus.INTRO:
        return "Offer"
      case OfferStatus.ESO_REVIEW:
        return "Contract"
      case OfferStatus.OFFER_READY:
        return "Contract"
      case OfferStatus.PRE_CLOSING:
        return "Funding"
      case OfferStatus.CONTRACT_REVIEW:
        return "Funding"
      case OfferStatus.POST_CLOSING:
        return "Liquidity"
      case OfferStatus.CLOSED:
        return "Liquidity"
      case OfferStatus.LIQUIDITY:
        return "You're All Set!"
      case OfferStatus.FULLY_FUNDED:
        return "Liquidity"
      default:
        return "Offer"
    }
  }

  const requestForDocument = async (documentType: string) => {
    const me = await getUser()
    const { didToken } = me
    const headers = { Authorization: `Bearer ${didToken}` }
    if (documentType === DOCUMENT_TYPE_NDA) {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL!}/documents/nda`, { dealId: deal.id }, { headers })
      const { data } = response
      if (data && data.success) {
        toast({
          title: `NDA successfully sent to your email! If you don't see it, please contact your equity partner.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: `Oops something went wrong while requesting for an NDA. In the meantime, please email ${deal.dealManager.email} to request an NDA`,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
    } else if (documentType === DOCUMENT_TYPE_BACKGROUND_CHECK) {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL!}/documents/bc`, { dealId: deal.id }, { headers })
      const { data } = response
      if (data && data.success) {
        toast({
          title: `Background Check successfully sent to your email! If you don't see it, please contact your equity partner.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: `Oops something went wrong while requesting for Background Check. In the meantime, please email ${deal.dealManager.email} to request a Background Check`,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  const getMainView = (deal: Deal) => {
    const { stage } = deal
    const PRECLOSING_STAGES = [
      OfferStatus.INTRO,
      OfferStatus.ESO_REVIEW,
      OfferStatus.OFFER_READY,
      OfferStatus.PRE_CLOSING,
      OfferStatus.CONTRACT_REVIEW,
    ]

    const isPreClosing = PRECLOSING_STAGES.includes(stage)
    if (isPreClosing) {
      const hideClosingDocs = stage === OfferStatus.INTRO || stage === OfferStatus.ESO_REVIEW || stage === OfferStatus.OFFER_READY
      const hideContractDocs = stage === OfferStatus.INTRO || stage === OfferStatus.ESO_REVIEW
      
      return (
        <PreClosingComponent
          deal={deal}
          documents={documents}
          hideClosingDocs={hideClosingDocs}
          hideContractDocs={hideContractDocs}
          addDocument={addDocument}
          requestForDocument={requestForDocument}
        />
      )
    } else {
      return (
        <LiquidityComponent />
      )
    }
  }

  return (
    <Stack id="top">
      <HStack pl={10} pt={10}>
        <Text fontSize="3xl">
          What's Next:
        </Text>
        <Text fontSize="3xl" color="brand.300" fontWeight="extrabold">
          { getWhatsNextText(deal) }
        </Text>
      </HStack>
      { getMainView(deal) }
    </Stack>
  )
}