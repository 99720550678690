import React from "react"
import { BiInfoCircle } from "react-icons/bi"
import { Center, HStack, Icon, List, ListItem, Stack, Text, Tooltip } from "@chakra-ui/react"
import { INSTRUCTIONS_ID_START, INSTRUCTIONS_ID_MID, INSTRUCTIONS_ID_END } from "../constants"
import { DocumentTypeDict } from "../../../constants"
import { useDevice } from "../../../hooks"

interface Props {
  id: string
  description: string
  instructions: string
  label: string
  icon: string
  type: string
}

export const DocumentLabel = ({ id, type, label, description, instructions, icon }: Props) => {
  const {isMobile} = useDevice()

  const idTooltipLabel = () => {
    return (
      <Stack>
        <Text>
          { INSTRUCTIONS_ID_START }
        </Text>
        <List>
          <ListItem>
            { INSTRUCTIONS_ID_MID }
          </ListItem>
          <ListItem>
            { INSTRUCTIONS_ID_END }
          </ListItem>
        </List>
      </Stack>
    )
  }

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id)
    if(element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  const renderTextWithBold = (description: string) => {
    const words = description.split(" ");
    return words.map((word: string, index: number) => {
      if (word.startsWith("**") && word.endsWith("**")) {
        // Bold word
        return (
          <strong key={index}>{word.substring(2, word.length - 2)}</strong>
        );
      } else {
        return <React.Fragment key={index}>{word} </React.Fragment>;
      }
    })
  }

  return (
    <HStack pb={5} cursor="pointer" onClick={() => scrollToElement(id)}>
      {
        !isMobile && (
        <Center w={55} h={55} bg="brand.300" borderRadius={50}>
          <Text color="white" fontSize="lg">
            { icon }
          </Text>
        </Center>
        )
      }
      
      <Stack>
        <Tooltip
          placement="bottom"
          label={
            type === DocumentTypeDict.driversLicenseDocument || type === DocumentTypeDict.passportDocument ? idTooltipLabel() : instructions
          }
        >
          <HStack>
            <Text textAlign="left" fontSize="xl" fontWeight="bold">
              { label }
            </Text>
            {
              !isMobile && (
                <Icon as={BiInfoCircle} h={4} w={4} color="brand.300" />
              )
            }
          </HStack>
        </Tooltip>
        <Text fontSize={12}>
          { renderTextWithBold(description) }
        </Text>
      </Stack>
    </HStack>
  )
}
