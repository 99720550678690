import { useState } from "react"
import {
  Box,
  Divider,
  Image,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react"
import { UploadDocumentRow } from "./UploadDocumentRow"
import { EsoDocumentIcon } from "./EsoDocumentIcon"
import {
  DESCRIPTION_NDA,
  DESCRIPTION_BC,
  DOCUMENT_INSTRUCTIONS_OFFER,
  DOCUMENT_INSTRUCTIONS_CONTRACT,
  DOCUMENT_TYPE_NDA,
  DOCUMENT_TYPE_BACKGROUND_CHECK,
  ID_MUTUAL_NDA,
  ID_409A_PROOF,
  ID_OPTIONS_VESTING_PROOF,
  ID_BC,
  ID_OPTION_PLAN,
  ID_OPTION_GRANT,
  ID_STOCK_CERTIFICATE,
  ID_DRIVERS_LICENSE,
  ID_PASSPORT,
  ID_UTILITY_BILL,
  INSTRUCTIONS_NDA,
  INSTRUCTIONS_BC,
  INSTRUCTIONS_OPTIONS_AND_SHARES,
  INSTRUCTIONS_OPTION_GRANT_DOCUMENTS,
  INSTRUCTIONS_STOCK_CERTIFICATE,
  INSTRUCTIONS_STOCK_OPTION_PLAN,
  get409aProofInstructions
} from "../constants"
import { DocumentTypeDict } from "../../../constants"
import { Deal, DealDocument, DocumentRow } from "../../../types"

interface Props {
  deal: Deal
  documents: DealDocument[]
  hideContractDocs: boolean
  hideClosingDocs: boolean
  addDocument: (key: string) => void
  requestForDocument?: (type: string) => Promise<void>
}

const SendingEsoDocumentIcon = () => {
  return (
    <VStack w={100} h={100}>
      <Image src="images/degree.png" alt="Certificate" />
      <Text fontSize={12} textAlign="center">
        Sending...
      </Text>
    </VStack>
  )
}

export const DealDocuments = ({
  deal,
  documents,
  hideContractDocs,
  hideClosingDocs,
  addDocument,
  requestForDocument,
}: Props) => {
  const [isSendingNda, setIsSendingNda] = useState(false)
  const [isSendingBc, setIsSendingBc] = useState(false)

  const INSTRUCTIONS_409A_PROOF = get409aProofInstructions(deal.company.name)

  const REQUIRED_OFFER_DOCS: DocumentRow[] = 
  [
    {
      id: ID_409A_PROOF,
      type: DocumentTypeDict.fmvProofDocument,
      label: "409A Proof",
      description: "Equity portal screenshot or company email trail confirming the current fair market value. **IMPORTANT**: Please make sure the **company name** and **current date** appear in any screenshot.",
      instructions: INSTRUCTIONS_409A_PROOF,
      icon: "409A",
    },
    {
      id: ID_OPTIONS_VESTING_PROOF,
      type: DocumentTypeDict.optionsVestingProofDocument,
      label: "Stock Options (or Shares) Vesting Proof",
      description: "Equity portal screenshot or grant documents confirming number of options, grant date, and strike price.",
      instructions: INSTRUCTIONS_OPTIONS_AND_SHARES,
      icon: "#",
    },
  ]

  const REQUIRED_CONTRACT_DOCS: DocumentRow[] = 
  [
    {
      id: ID_OPTION_PLAN,
      type: DocumentTypeDict.optionPlanDocument,
      label: "Stock Option Plan",
      description: "Document governing stock option and share ownership at your company",
      instructions: INSTRUCTIONS_STOCK_OPTION_PLAN,
      icon: "SOP",
    },
    {
      id: ID_OPTION_GRANT,
      type: DocumentTypeDict.optionGrantDocument,
      label: "Stock Option Grant Document (for each grant)",
      description: "Document governing each individual stock option grant",
      instructions: INSTRUCTIONS_OPTION_GRANT_DOCUMENTS,
      icon: "SOG",
    },
    {
      id: ID_STOCK_CERTIFICATE,
      type: DocumentTypeDict.stockCertificateDocument,
      label: "Stock Certificate Proof (Shares Only)",
      description: "Screenshot of your stock certificate",
      instructions: INSTRUCTIONS_STOCK_CERTIFICATE,
      icon: "CS",
    },
  ]

  const REQUIRED_CLOSING_DOCS: DocumentRow[] = 
  [
    {
      id: ID_DRIVERS_LICENSE,
      type: DocumentTypeDict.driversLicenseDocument,
      label: "Driver's License",
      description: "Please provide a 1st valid photo ID, ideally a copy of your driver's license",
      instructions: "",
      icon: "ID",
    },
    {
      id: ID_PASSPORT,
      type: DocumentTypeDict.passportDocument,
      label: "Passport",
      description: "Please provide a 2nd valid photo ID, ideally a copy of your passport and/or green card if applicable",
      instructions: "",
      icon: "ID",
    },
    {
      id: ID_UTILITY_BILL,
      type: DocumentTypeDict.utilityBillDocument,
      description: "Please provide a utility bill from your current address IF the address of your driver's license does not match the address in our contact information.",
      label: "Utility Bill (optional)",
      instructions: "",
      icon: "ID",
    },
  ]

  const getContractDocuments = () => {
    return (
      <>
        <Text pt={10} fontSize="2xl" fontWeight="bold">
          Required Documents (Contract)
        </Text>
        <Text fontSize="md">
          { DOCUMENT_INSTRUCTIONS_CONTRACT }
        </Text>
        {
          isSendingBc ? (
            <SendingEsoDocumentIcon key={ID_BC} />
          ) : (
            <EsoDocumentIcon
              id={ID_BC}
              key={ID_BC}
              instructions={INSTRUCTIONS_BC}
              description={DESCRIPTION_BC}
              name={DOCUMENT_TYPE_BACKGROUND_CHECK}
              requestForDocument={sendBc}
            />
          )
        }
        {
          REQUIRED_CONTRACT_DOCS.map(document => {
            return (
              <UploadDocumentRow
                key={document.id}
                deal={deal}
                documentRow={document}
                isUploaded={documents.some(doc => doc.type === document.type)}
                addDocument={addDocument}
              />
            )
          })
        }
        <Divider />
      </>
    )
  }

  const getClosingDocuments = () => {
    return (
      <>
        <Text pt={10} fontSize="2xl" fontWeight="bold">
          Required Documents (Closing)
        </Text>
        {
          REQUIRED_CLOSING_DOCS.map(document => {
            return (
              <UploadDocumentRow
                key={document.id}
                deal={deal}
                documentRow={document}
                isUploaded={documents.some(doc => doc.type === document.type)}
                addDocument={addDocument}
              />
            )
          })
        }
        <Divider />
      </>
    )
  }

  const sendNda = async () => {
    if (!requestForDocument) {
      console.log("No requestForDocument function")
      return
    }
    setIsSendingNda(true)
    await requestForDocument(DOCUMENT_TYPE_NDA)
    setIsSendingNda(false)
  }

  const sendBc = async () => {
    if (!requestForDocument) {
      console.log("No requestForDocument function")
      return
    }
    setIsSendingBc(true)
    await requestForDocument(DOCUMENT_TYPE_BACKGROUND_CHECK)
    setIsSendingBc(false)
  }

  // const handleDownload = async () => {
  //   try {
  //     const me = await getUser()
  //     const { didToken } = me
  //     const headers = { Authorization: `Bearer ${didToken}` }
  //     const res = await axios.post(`${process.env.REACT_APP_SERVER_URL!}/documents/contract`, {
  //       dealId: deal.id
  //     }, { headers, responseType: "arraybuffer" })
  //     const url = window.URL.createObjectURL(new Blob([res.data]));
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', `${deal.company.name} - ${deal.client.firstName} ${deal.client.lastName} - Combo DocuSign PPA4 - Fully Executed.pdf`);
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) {
  //     console.error('Error downloading file:', error);
  //   }
  // }
  
  return (
    <Box key={Math.random()} pl={10} pr={10} pb={10}>
      {/* <Button onClick={() => handleDownload()}>Download Contract</Button> */}
      <Stack spacing={10} pt={10} overflowY="scroll">
        {
          !hideClosingDocs && getClosingDocuments()
        }
        {
          !hideContractDocs && getContractDocuments()
        }
        <Text fontSize="2xl" fontWeight="bold">
          Submit Information (Offer)
        </Text>
        <Text fontSize="md">
          { DOCUMENT_INSTRUCTIONS_OFFER }
        </Text>
        {
          isSendingNda ? (
            <SendingEsoDocumentIcon key={ID_MUTUAL_NDA} />
          ) : (
            <EsoDocumentIcon
              id={ID_MUTUAL_NDA}
              key={ID_MUTUAL_NDA}
              instructions={INSTRUCTIONS_NDA}
              description={DESCRIPTION_NDA}
              name={DOCUMENT_TYPE_NDA}
              requestForDocument={sendNda}
            />
          )
        }
        <Text fontSize="lg" fontWeight="bold">
          After signing the NDA please provide the following information:
        </Text>
        {
          REQUIRED_OFFER_DOCS.map(document => {
            return (
              <>
                <UploadDocumentRow
                  key={document.id}
                  deal={deal}
                  documentRow={document}
                  isUploaded={documents.some(doc => doc.type === document.type)}
                  addDocument={addDocument}
                />
              </>
            )
          })
        }
      </Stack>
    </Box>
  )
}
