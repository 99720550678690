import { BiInfoCircle } from "react-icons/bi"
import { FaCalendarAlt, FaFileSignature } from "react-icons/fa"
import {
  Button,
  Center,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react"
import { useDevice } from "../../../hooks"

interface Props {
  id: string
  instructions: string
  description?: string
  name: string
  requestForDocument?: (type: string) => Promise<void>
}

export const EsoDocumentIcon = ({ id, instructions, description, name, requestForDocument }: Props) => {
  const CLOSING_NAME = "Schedule your Closing Meeting"
  const { isMobile } = useDevice()
  
  const scrollToElement = (id: string) => {
    if (requestForDocument) return
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <HStack key={name} id={requestForDocument ? id : ""} pb={5} w="100%" h="100%" cursor="pointer" onClick={() => scrollToElement(id)}>
      {
        !isMobile && (
          <Stack h="100%">
            <Center w={55} h={55} bg="brand.300" borderRadius={50}>
              <Icon color="white" as={name === CLOSING_NAME ? FaCalendarAlt : FaFileSignature} w={5} h={5} pl={1} />
            </Center>
            <Spacer />
          </Stack>
      )}
      <Stack w="70%">
        <Tooltip label={instructions} placement="bottom">
          <HStack>
            <Text fontSize="xl" fontWeight="bold">
              { name }
            </Text>
            {
              !isMobile && instructions !== "" && (
                <Icon as={BiInfoCircle} h={4} w={4} color="brand.300" />
            )}
          </HStack>
        </Tooltip>
        {
          description && (
            <Text fontSize="sm">
              { description }
            </Text>
          )
        }
        {
          requestForDocument && (
            <Button onClick={() => requestForDocument(name)} variant="outline" borderColor="brand.300" color="brand.300" size="sm" w={100}>
              Request
            </Button>
          )
        }
      </Stack>
    </HStack>
  )
}