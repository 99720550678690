import { BiChevronLeft } from "react-icons/bi"
import { useNavigate } from "react-router-dom"
import { HStack, Icon, Spacer, Text } from "@chakra-ui/react"

export const ReturnToPortfolioLink = () => {
  const navigate = useNavigate()
  
  return (
    <HStack
      cursor="pointer"
      spacing={0}
      onClick={() => navigate("/portfolio")}
      pt={5}
    >
      <Spacer />
      <Icon
        color="brand.300"
        as={BiChevronLeft}
        w={5}
      />
      <Text
        fontSize="sm"
        color="brand.300"
        pr={5}
      >
        Return to Homepage
      </Text>
    </HStack>
  )
}
